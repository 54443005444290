/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import userData from '../user-data.json';

export const AuthContext = createContext();
export const useAuthData = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  userData.userInput.rememberUsername = ls.get('BlendRememberMe') === true;

  if (userData.userInput.rememberUsername) {
    userData.userInput.username = ls.get('BlendUserName');
    userData.username = ls.get('BlendUserName');
  }

  const [authData, setAuthData] = useState({
    ...userData,
    globalErrorMessage: '',
    inputErrorMessage: '',
  });

  const updateUserInput = (data) => {
    setAuthData({
      ...authData,
      userInput: {
        ...authData.userInput,
        ...data,
      },
      validation: {
        ...authData.validation,
        [Object.keys(data)[0]]: true,
      },
    });
  };

  const clearUserInput = () => {
    setAuthData({
      ...authData,
      userInput: {
        username: '',
        rememberUsername: false,
        password: '',
        newPassword: '',
        confirmNewPassword: '',
        answer: '',
        askedQ: '',
      },
    });
  };

  const setValidation = (inputs) => {
    let validatedInputs = {};

    inputs.forEach((input) => {
      validatedInputs = {
        ...validatedInputs,
        [input]: !!authData.userInput[input],
      };

      return validatedInputs;
    });

    setAuthData({
      ...authData,
      validation: {
        ...authData.validation,
        ...validatedInputs,
      },
      globalErrorMessage: '',
      inputErrorMessage: '',
    });
  };

  const setGlobalErrorMessage = (data) => {
    setAuthData({
      ...authData,
      globalErrorMessage: data.globalErrorMessage,
    });
  };

  const setInputErrorMessage = (data) => {
    setAuthData({
      ...authData,
      inputErrorMessage: data.inputErrorMessage,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authData,
        updateUserInput,
        clearUserInput,
        setValidation,
        setGlobalErrorMessage,
        setInputErrorMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};

AuthContextProvider.defaultProps = {
  children: undefined,
};

export default AuthContextProvider;
