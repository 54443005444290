/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const IconPlus = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Plus Icon</title>
    <g id="plus-circle-icon" transform="translate(0.000000, -0.000000)">
      <path
        d="M25,4.61852778e-14 C11.1943249,4.61852778e-14 0,11.1921286 0,24.9989019 C0,38.8056751 11.1932267,49.9978037 25,49.9978037 C38.8078714,49.9978037 50,38.8056751 50,24.9989019 C50,11.1921286 38.8078714,4.61852778e-14 25,4.61852778e-14 Z"
        fill="#5E9C11"
      />
      <path
        d="M25,15.2 C25.9444069,15.2 26.7189407,15.9273134 26.7940331,16.852372 L26.8,17 L26.8,22.879 L32.68,22.88 C33.6741125,22.88 34.48,23.6858875 34.48,24.68 C34.48,25.6244069 33.7526866,26.3989407 32.827628,26.4740331 L32.68,26.48 L26.8,26.479 L26.8,32.36 C26.8,33.3541125 25.9941125,34.16 25,34.16 C24.0555931,34.16 23.2810593,33.4326866 23.2059669,32.507628 L23.2,32.36 L23.2,26.479 L17.32,26.48 C16.3258875,26.48 15.52,25.6741125 15.52,24.68 C15.52,23.7355931 16.2473134,22.9610593 17.172372,22.8859669 L17.32,22.88 L23.2,22.879 L23.2,17 C23.2,16.0058875 24.0058875,15.2 25,15.2 Z"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);

IconPlus.propTypes = {
  className: PropTypes.string,
};

IconPlus.defaultProps = {
  className: '',
};

export default IconPlus;
