/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

import iconEqualHousing from '@ux/balance-base/build/assets/images/icon-equal-housing.svg';
import iconFDIC from '@ux/balance-base/build/assets/images/icon-fdic.svg';

const year = new Date().getFullYear();

const FooterLegal = ({ footerCopy, footerIcons }) => (
  <div className="footer__legal--global">
    <div className="footer__legal--global-copy">
      {footerCopy.map((p) => (
        <p
          key={p}
          className="footer__copy"
          dangerouslySetInnerHTML={{ __html: p }} // eslint-disable-line
        />
      ))}
    </div>
    <div className="footer__icons">
      {footerIcons.map((icon) => (
        <img src={icon.iconUrl} alt={icon.iconAltText} key={icon.iconUrl} />
      ))}
    </div>
  </div>
);

FooterLegal.propTypes = {
  footerCopy: PropTypes.arrayOf(PropTypes.string),
  footerIcons: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ),
};

FooterLegal.defaultProps = {
  footerCopy: [
    `&copy; ${year} Regions Bank. All Rights Reserved.`,
    'Regions, the Regions logo and the LifeGreen bike are registered trademarks of Regions Bank. The LifeGreen color is a trademark of Regions Bank.',
  ],
  footerIcons: [
    { iconUrl: iconEqualHousing, iconAltText: 'Equal Housing Lender logo' },
    { iconUrl: iconFDIC, iconAltText: 'FDIC logo' },
  ],
};

export default FooterLegal;
