/* eslint-disable react/no-danger */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@ux/balance-react';

import IconAlert from '../icons/Alert';

const AuthHeading = ({ h1, description, prompt, error }) => (
  <Stack verticalSpacing="80" isCentered classes="auth-heading">
    <h1 className="centered text-style-heading-2">{h1}</h1>
    {description && (
      <p
        dangerouslySetInnerHTML={{ __html: description }}
        className="auth-heading__description"
      />
    )}
    {prompt && (
      <p
        dangerouslySetInnerHTML={{ __html: prompt }}
        className="auth-heading__prompt"
      />
    )}
    {error && (
      <p className="auth-heading__error">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <span
            style={{ position: 'absolute', marginTop: '.25em' }}
            className="form-control__error-icon"
            aria-hidden="true"
          >
            <IconAlert color="#051675" />
          </span>
          <span
            style={{ marginLeft: '1.5em', color: 'black', fontWeight: '600' }}
            dangerouslySetInnerHTML={{ __html: error }} // eslint-disable-line
          />
        </div>
      </p>
    )}
  </Stack>
);

AuthHeading.propTypes = {
  h1: PropTypes.string,
  description: PropTypes.string,
  prompt: PropTypes.string,
  error: PropTypes.string,
};

AuthHeading.defaultProps = {
  h1: '',
  description: '',
  prompt: '',
  error: '',
};

export default AuthHeading;
