/* eslint-disable react/function-component-definition */
import React from 'react';
import { Container, Link, Stack } from '@ux/balance-react';
import Layout from './Layout';
import AuthHeading from './AuthHeading';
import { GetBaseUrl } from '../Utility';

const PageNotFound = () => {
  const message =
    'Sorry, you have requested a page or file that does not exist. Please make sure you entered the correct web address and try again. Our Help & Support pages offer additional guidance, phone numbers and answers to common questions. If you prefer to speak with someone, please call our Customer Service Center at 1-800-REGIONS.';

  if (window.location.href.startsWith(`${GetBaseUrl()}/auth`)) {
    window.location.replace(`${GetBaseUrl()}/notfound`);
  }

  return (
    <Layout>
      <Container verticalSpacing="30">
        <div className="content-wrapper">
          <Stack verticalSpacing="70" isCentered>
            <AuthHeading
              h1="Page Not Found"
              description=""
              prompt={null}
              error={null}
            />
            <div style={{ width: '100%' }}>
              <Stack isCentered verticalSpacing="70">
                <Link
                  variant="primary"
                  text="Return Home"
                  href={GetBaseUrl()}
                />
                <p className="centered text-style-body-2">{message}</p>
              </Stack>
            </div>
          </Stack>
        </div>
      </Container>
    </Layout>
  );
};

export default PageNotFound;
