/* eslint-disable react/function-component-definition */
import React from 'react';
// import { useLocation } from '@reach/router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Stack, Tile } from '@ux/balance-react';
import Layout from '../components/Layout';
import IconPlus from '../icons/Plus';
import IconUser from '../icons/User';
import IconChevron from '../icons/Chevron';
import AuthHeading from '../components/AuthHeading';
import { SiteUrls, ReferralProduct } from '../EnvironmentConstants';
import Logger from '../Logger';

const Segmentation = () => {
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  let newCustomerUrl = '';
  let { product } = searchParams;
  let errorMessage = '';
  const { message } = searchParams;
  if (!product) {
    product = ReferralProduct.Heloc;
  }

  if (product === ReferralProduct.Heloc) {
    newCustomerUrl = SiteUrls.BlendNewCustomer;
  }

  if (!message) {
    Logger.DebugMessage('No Message');
  } else if (message.toLowerCase() === 'businessuser') {
    Logger.DebugMessage('Message Found');
    errorMessage =
      'Business accounts are not eligible for Home Equity Lines of Credit. Log in to your Regions personal account or start the application with your email.';
  }

  return (
    <Layout>
      <Container verticalSpacing="30">
        <Stack verticalSpacing="70" isCentered>
          <AuthHeading
            h1="Apply for a Home Equity Line of Credit"
            description=""
            error={errorMessage}
          />

          <Stack verticalSpacing="50" isCentered classes="segmentation-wrapper">
            <a href={SiteUrls.EnterpriseAuth} className="large-icon-button">
              <Tile>
                <span>
                  <span>
                    <IconUser />
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ order: 1 }}>
                      Login with Regions Online Banking
                    </div>
                    <div
                      style={{
                        order: 2,
                        color: 'black',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        letterSpacing: '-0.13px',
                      }}
                    >
                      Access your personal account with your online username and
                      password.
                    </div>
                  </div>
                </span>{' '}
                <IconChevron className="icon icon--right" />
              </Tile>
            </a>
            <a href={newCustomerUrl} className="large-icon-button">
              <Tile>
                <span>
                  <span>
                    <IconPlus />
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ order: 1 }}>
                      Start application using your email
                    </div>
                    <div
                      style={{
                        order: 2,
                        color: 'black',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        letterSpacing: '-0.13px',
                      }}
                    >
                      If you do not have a personal account, please start the
                      application process with your personal email address.
                    </div>
                  </div>
                </span>{' '}
                <IconChevron className="icon icon--right" />
              </Tile>
            </a>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
};

export default Segmentation;
