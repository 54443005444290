import { UserNamePageErrorCodes } from './EnvironmentConstants';

export const RedirectToHomePage = () => {
  let port = '';
  if (
    window.location.port &&
    window.location.port !== 0 &&
    window.location.port !== '0'
  ) {
    port = `:${window.location.port}`;
  }

  window.location.replace(
    `${window.location.protocol}//${window.location.hostname}${port}`
  );
};

export const RedirectToUserNamePage = (product, sessionexpired) => {
  let port = '';
  if (
    window.location.port &&
    window.location.port !== 0 &&
    window.location.port !== '0'
  ) {
    port = `:${window.location.port}`;
  }

  if (sessionexpired) {
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}${port}/auth?product=${product}&errorCode=${UserNamePageErrorCodes.SessionExpired}`
    );
  } else {
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}${port}/auth?product=${product}`
    );
  }
};

export const GetBaseUrl = () => {
  let port = '';
  if (
    window.location.port &&
    window.location.port !== 0 &&
    window.location.port !== '0'
  ) {
    port = `:${window.location.port}`;
  }

  return `${window.location.protocol}//${window.location.hostname}${port}`;
};
