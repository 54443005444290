/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const FooterLinksList = ({ children }) => (
  <nav className="footer__nav" role="navigation">
    <ul className="footer__nav-list" role="list">
      {children}
    </ul>
  </nav>
);

FooterLinksList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FooterLinksList;
