/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Container, Link, Stack } from '@ux/balance-react';
import { SiteUrls } from '../EnvironmentConstants';
import Layout from './Layout';
import AuthHeading from './AuthHeading';

const BusinessUserMessaging = () => {
  const newCustomerUrl = SiteUrls.BlendNewCustomer;
  const errorMessage =
    'We are unable to log you in using a business profile. Please use the username and password for your personal account. If you do not have a personal account, please start the application using your personal email address.';

  return (
    <Layout>
      <Container verticalSpacing="30">
        <div className="content-wrapper">
          <Stack verticalSpacing="70" isCentered>
            <AuthHeading
              h1="Apply for a Home Equity Line of Credit"
              description="For your security, we have locked your account."
              prompt={'You can still apply using your email address.' || null}
              error={'' || null}
            />
            <div style={{ width: '100%' }}>
              <Stack isCentered verticalSpacing="70">
                <Link
                  variant="primary"
                  text="Start my application"
                  href={newCustomerUrl}
                />
                <p className="centered text-style-body-2">{errorMessage}</p>
              </Stack>
            </div>
          </Stack>
        </div>
      </Container>
    </Layout>
  );
};

export default BusinessUserMessaging;
