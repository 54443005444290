/* eslint-disable react/function-component-definition */
import React from 'react';
// import { Link } from '@reach/router';
import { Link } from 'react-router-dom';
import { Logo } from '@ux/balance-react';

const Header = () => (
  <header className="header" role="banner">
    <Link to="/">
      <Logo />
    </Link>
  </header>
);

export default Header;
