/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const IconUser = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>User Icon</title>
    <g transform="translate(-39.000000, -35.000000)" fillRule="nonzero">
      <g transform="translate(39.000000, 35.000000)">
        <path
          d="M25,0 C11.1943249,0 0,11.1921286 0,24.9989019 C0,38.8056751 11.1932267,49.9978037 25,49.9978037 C38.8078714,49.9978037 50,38.8056751 50,24.9989019 C50,11.1921286 38.8078714,0 25,0 Z"
          fill="#5E9C11"
        />
        <path
          d="M25,7.47496266 C29.5682158,7.47496266 33.2700079,11.1778529 33.2700079,15.7438724 C33.2700079,20.3109901 29.5682158,24.0127822 25,24.0127822 C20.4339805,24.0127822 16.7321884,20.3109901 16.7321884,15.7438724 C16.7321884,11.1778529 20.4339805,7.47496266 25,7.47496266 Z"
          fill="#FFFFFF"
        />
        <path
          d="M24.9945094,43.4617412 C20.438373,43.4617412 16.2654836,41.8024686 13.0468681,39.0560485 C12.2628042,38.3872881 11.8103751,37.4066591 11.8103751,36.3777124 C11.8103751,31.7469033 15.5582887,28.0407186 20.1901959,28.0407186 L29.8120004,28.0407186 C34.4450057,28.0407186 38.1786436,31.7469033 38.1786436,36.3777124 C38.1786436,37.4077572 37.7284108,38.3861899 36.9432487,39.0549504 C33.7257314,41.8024686 29.5517438,43.4617412 24.9945094,43.4617412 Z"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
);

IconUser.propTypes = {
  className: PropTypes.string,
};

IconUser.defaultProps = {
  className: '',
};

export default IconUser;
