/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@ux/balance-react';

const FooterLink = ({ linkText, hrefPath, dataTestId }) => (
  <li className="footer__nav-item">
    <Link
      className="footer__nav-link"
      href={hrefPath}
      text={linkText}
      size="small"
      variant="text-secondary"
      data-testid={dataTestId}
    />
  </li>
);

FooterLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  hrefPath: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};

FooterLink.defaultProps = {
  dataTestId: null,
};

export default FooterLink;
