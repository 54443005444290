import { Logging } from './EnvironmentConstants';

export default class Logger {
  static LogMessage(logLevel, message) {
    console.log(`${Date().toLocaleString()} ${logLevel} Message="${message}"`);
  }

  static DebugMessage(message) {
    if (Logging.EnabledLevels.includes(Logging.Level.Debug)) {
      Logger.LogMessage(Logging.Level.Debug, message);

      if (Logging.AlertDebugLogs) {
        alert(message);
      }
    }
  }

  static DebugObject(object) {
    if (Logging.EnabledLevels.includes(Logging.Level.Debug)) {
      Logger.LogMessage(Logging.Level.Debug, 'Object Logged Below');
      console.log(object);

      if (Logging.AlertDebugLogs) {
        alert(object);
      }
    }
  }

  static DebugMessageWithObject(message, object) {
    if (Logging.EnabledLevels.includes(Logging.Level.Debug)) {
      Logger.LogMessage(Logging.Level.Debug, message);
      console.log(object);

      if (Logging.AlertDebugLogs) {
        alert(message);
        alert(object);
      }
    }
  }

  static ErrorMessage(message) {
    if (Logging.EnabledLevels.includes(Logging.Level.Error)) {
      Logger.LogMessage(Logging.Level.Error, message);
    }
  }

  static ErrorObject(object) {
    if (Logging.EnabledLevels.includes(Logging.Level.Error)) {
      Logger.LogMessage(Logging.Level.Error, 'Object Logged Below');
      console.log(object);
    }
  }

  static ErrorMessageWithObject(message, object) {
    if (Logging.EnabledLevels.includes(Logging.Level.Error)) {
      Logger.LogMessage(Logging.Level.Error, message);
      console.log(object);
    }
  }

  static InfoMessage(message) {
    if (Logging.EnabledLevels.includes(Logging.Level.Info)) {
      Logger.LogMessage(Logging.Level.Info, message);
    }
  }

  static InfoObject(object) {
    if (Logging.EnabledLevels.includes(Logging.Level.Info)) {
      Logger.LogMessage(Logging.Level.Info, 'Object Logged Below');
      console.log(object);
    }
  }

  static InfoMessageWithObject(message, object) {
    if (Logging.EnabledLevels.includes(Logging.Level.Info)) {
      Logger.LogMessage(Logging.Level.Info, message);
      console.log(object);
    }
  }
}
