/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconAlert = ({ classes, color }) => (
  <svg
    className={classNames('icon', 'icon--alert', classes)}
    viewBox="0 0 22 22"
    version="1.1"
  >
    <title>Alert Icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g transform="translate(11.000000, 11.000000) scale(1, -1) translate(-11.000000, -11.000000) ">
          <path
            d="M11,1 C13.7575027,1 16.2572839,2.12161807 18.0678329,3.93216706 C19.8783819,5.74271605 21,8.24249731 21,11 C21,13.7575027 19.8783819,16.2572839 18.0678329,18.0678329 C16.2572839,19.8783819 13.7575027,21 11,21 C8.24249731,21 5.74271605,19.8783819 3.93216706,18.0678329 C2.12161807,16.2572839 1,13.7575027 1,11 C1,8.24249731 2.12161807,5.74271605 3.93216706,3.93216706 C5.74271605,2.12161807 8.24249731,1 11,1 Z"
            stroke={color}
            strokeWidth="2"
          />
          <path
            d="M10.9232955,9.82507895 C11.5565427,9.82507895 12.0395489,10.4030864 12.0395489,11.0822105 L12.0395489,11.0822105 L12.0395489,15.3678684 C12.0395489,16.0469925 11.5565427,16.625 10.9232955,16.625 C10.2900482,16.625 9.80704201,16.0469925 9.80704201,15.3678684 L9.80704201,15.3678684 L9.80704201,11.0822105 C9.80704201,10.4030864 10.2900482,9.82507895 10.9232955,9.82507895 Z M10.9232955,5.27088816 C11.6543703,5.27088816 12.2465909,5.87090118 12.2465909,6.60633224 C12.2465909,7.34176329 11.6543703,7.94177632 10.9232955,7.94177632 C10.1922206,7.94177632 9.6,7.34176329 9.6,6.60633224 C9.6,5.87090118 10.1922206,5.27088816 10.9232955,5.27088816 Z"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
);

IconAlert.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
};

IconAlert.defaultProps = {
  classes: '',
  color: 'currentColor',
};

export default IconAlert;
