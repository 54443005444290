/* eslint-disable react/function-component-definition */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { useLocation } from '@reach/router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Header from './Header';
import Footer from './Footer';
import FooterDisclosures from './Footer/FooterDisclosures';
import FooterLinksList from './Footer/FooterLinksList';
import FooterLink from './Footer/FooterLink';
import FooterLegal from './Footer/FooterLegal';

import { ReferralProduct, SiteUrls } from '../EnvironmentConstants';

const Layout = ({ children }) => {
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const { product } = searchParams;

  let footerDisclosure = null;

  switch (product) {
    case ReferralProduct.Heloc:
      footerDisclosure = [
        `Blend Labs, Inc. (“Blend”) hosts the online credit application platform for Regions Bank.  Blend is not an affiliate of Regions Bank. Use of the application platform is subject to Blend’s terms of use and privacy policies.  Blend’s terms of use and privacy policies are provided at the application platform. Applicants should review them before using the application platform.`,
        `To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.`,
      ];
      break;
    default:
      footerDisclosure = [
        `To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.`,
      ];
      break;
  }

  return (
    <Fragment>
      <a className="skip-link" href="#main">
        skip to main content
      </a>
      <Header />
      <main className="main" id="main" role="main">
        {children}
      </main>
      <Footer>
        {footerDisclosure && (
          <FooterDisclosures disclosures={footerDisclosure} />
        )}
        <FooterLinksList>
          <FooterLink linkText="About Regions" hrefPath={SiteUrls.About} />
          <FooterLink
            linkText="Investor Relations"
            hrefPath={SiteUrls.InvestorRelations}
          />
          <FooterLink
            linkText="Privacy & Security"
            hrefPath={SiteUrls.PrivacyAndSecurity}
          />
          <FooterLink
            linkText="Website Terms of Use"
            hrefPath={SiteUrls.WebsiteTermsOfUse}
          />
          <FooterLink
            linkText="Online Tracking &amp; Advertising"
            hrefPath={SiteUrls.OnlineTrackingAdvertising}
          />
          <FooterLink
            linkText="Accessible Banking"
            hrefPath={SiteUrls.AccessibleBanking}
          />
          <FooterLink
            linkText="Leave Feedback"
            hrefPath={SiteUrls.LeaveFeedback}
          />
        </FooterLinksList>
        <FooterLegal />
      </Footer>
    </Fragment>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
