import {
  LockedOutErrorCodes as LocalLockedOutErrorCodes,
  Logging as LocalLogging,
  ReferralSite as LocalReferralSite,
  ReferralProduct as LocalReferralProduct,
  SessionTimeOut as LocalSessionTimeOut,
  SiteUrls as LocalSiteUrls,
  TokenResult as LocalTokenResult,
  TransmitConnection as LocalTransmitConnection,
  UserNamePageErrorCodes as LocalUserNamePageErrorCodes,
} from './configsettings/local_settings';

import {
  LockedOutErrorCodes as DevLockedOutErrorCodes,
  Logging as DevLogging,
  ReferralSite as DevReferralSite,
  ReferralProduct as DevReferralProduct,
  SessionTimeOut as DevSessionTimeOut,
  SiteUrls as DevSiteUrls,
  TokenResult as DevTokenResult,
  TransmitConnection as DevTransmitConnection,
  UserNamePageErrorCodes as DevUserNamePageErrorCodes,
} from './configsettings/dev_settings';

import {
  LockedOutErrorCodes as TestLockedOutErrorCodes,
  Logging as TestLogging,
  ReferralSite as TestReferralSite,
  ReferralProduct as TestReferralProduct,
  SessionTimeOut as TestSessionTimeOut,
  SiteUrls as TestSiteUrls,
  TokenResult as TestTokenResult,
  TransmitConnection as TestTransmitConnection,
  UserNamePageErrorCodes as TestUserNamePageErrorCodes,
} from './configsettings/test_settings';

import {
  LockedOutErrorCodes as QALockedOutErrorCodes,
  Logging as QALogging,
  ReferralSite as QAReferralSite,
  ReferralProduct as QAReferralProduct,
  SessionTimeOut as QASessionTimeOut,
  SiteUrls as QASiteUrls,
  TokenResult as QATokenResult,
  TransmitConnection as QATransmitConnection,
  UserNamePageErrorCodes as QAUserNamePageErrorCodes,
} from './configsettings/qa_settings';

import {
  LockedOutErrorCodes as DRLockedOutErrorCodes,
  Logging as DRLogging,
  ReferralSite as DRReferralSite,
  ReferralProduct as DRReferralProduct,
  SessionTimeOut as DRSessionTimeOut,
  SiteUrls as DRSiteUrls,
  TokenResult as DRTokenResult,
  TransmitConnection as DRTransmitConnection,
  UserNamePageErrorCodes as DRUserNamePageErrorCodes,
} from './configsettings/dr_settings';

import {
  LockedOutErrorCodes as ProdLockedOutErrorCodes,
  Logging as ProdLogging,
  ReferralSite as ProdReferralSite,
  ReferralProduct as ProdReferralProduct,
  SessionTimeOut as ProdSessionTimeOut,
  SiteUrls as ProdSiteUrls,
  TokenResult as ProdTokenResult,
  TransmitConnection as ProdTransmitConnection,
  UserNamePageErrorCodes as ProdUserNamePageErrorCodes,
} from './configsettings/prod_settings';

// Create private, non exported, variables
let referralSite;
let referralProduct;
let lockedOutErrorCodes;
let logging;
let sessionTimeOut;
let siteUrls;
let tokenResult;
let transmitConnection;
let userNamePageErrorCodes;

export const Environments = {
  Local: 'LOCAL',
  Dev: 'DEV',
  Test: 'TEST',
  QA: 'QA',
  DR: 'DR',
  Prod: 'PROD',
  Current: () => {
    const domain = window.location.hostname;
    let env = 'LOCAL';

    switch (domain) {
      case 'internal-blnd-ui-dev.apps.np-dmz-b.openshift.rgbk.com':
        env = 'DEV';
        break;
      case 'origination-dev.regionstest.com':
        env = 'DEV';
        break;
      case 'internal-blnd-ui-test.apps.np-dmz-b.openshift.rgbk.com':
        env = 'TEST';
        break;
      case 'origination-test.regionstest.com':
        env = 'TEST';
        break;
      case 'internal-blnd-ui-qa.apps.np-dmz-b.openshift.rgbk.com':
        env = 'QA';
        break;
      case 'origination-qa.regionstest.com':
        env = 'QA';
        break;
      case 'internal-blnd-ui-dr.apps.prod-dmz-b.openshift.rgbk.com':
        env = 'DR';
        break;
      case 'origination.regions.com':
        env = 'PROD';
        break;
      default:
      // do nothing
    }

    return env;
  },
};

// Set local variables to appropriate environment config file
const currEnv = Environments.Current();

if (currEnv === Environments.Dev) {
  referralSite = DevReferralSite;
  referralProduct = DevReferralProduct;
  lockedOutErrorCodes = DevLockedOutErrorCodes;
  logging = DevLogging;
  sessionTimeOut = DevSessionTimeOut;
  siteUrls = DevSiteUrls;
  tokenResult = DevTokenResult;
  transmitConnection = DevTransmitConnection;
  userNamePageErrorCodes = DevUserNamePageErrorCodes;
} else if (currEnv === Environments.Test) {
  referralSite = TestReferralSite;
  referralProduct = TestReferralProduct;
  lockedOutErrorCodes = TestLockedOutErrorCodes;
  logging = TestLogging;
  sessionTimeOut = TestSessionTimeOut;
  siteUrls = TestSiteUrls;
  tokenResult = TestTokenResult;
  transmitConnection = TestTransmitConnection;
  userNamePageErrorCodes = TestUserNamePageErrorCodes;
} else if (currEnv === Environments.QA) {
  referralSite = QAReferralSite;
  referralProduct = QAReferralProduct;
  lockedOutErrorCodes = QALockedOutErrorCodes;
  logging = QALogging;
  sessionTimeOut = QASessionTimeOut;
  siteUrls = QASiteUrls;
  tokenResult = QATokenResult;
  transmitConnection = QATransmitConnection;
  userNamePageErrorCodes = QAUserNamePageErrorCodes;
} else if (currEnv === Environments.DR) {
  referralSite = DRReferralSite;
  referralProduct = DRReferralProduct;
  lockedOutErrorCodes = DRLockedOutErrorCodes;
  logging = DRLogging;
  sessionTimeOut = DRSessionTimeOut;
  siteUrls = DRSiteUrls;
  tokenResult = DRTokenResult;
  transmitConnection = DRTransmitConnection;
  userNamePageErrorCodes = DRUserNamePageErrorCodes;
} else if (currEnv === Environments.Prod) {
  referralSite = ProdReferralSite;
  referralProduct = ProdReferralProduct;
  lockedOutErrorCodes = ProdLockedOutErrorCodes;
  logging = ProdLogging;
  sessionTimeOut = ProdSessionTimeOut;
  siteUrls = ProdSiteUrls;
  tokenResult = ProdTokenResult;
  transmitConnection = ProdTransmitConnection;
  userNamePageErrorCodes = ProdUserNamePageErrorCodes;
} else {
  referralSite = LocalReferralSite;
  referralProduct = LocalReferralProduct;
  lockedOutErrorCodes = LocalLockedOutErrorCodes;
  logging = LocalLogging;
  sessionTimeOut = LocalSessionTimeOut;
  siteUrls = LocalSiteUrls;
  tokenResult = LocalTokenResult;
  transmitConnection = LocalTransmitConnection;
  userNamePageErrorCodes = LocalUserNamePageErrorCodes;
}

export const ReferralProduct = referralProduct;
export const ReferralSite = referralSite;
export const LockedOutErrorCodes = lockedOutErrorCodes;
export const Logging = logging;
export const SessionTimeOut = sessionTimeOut;
export const SiteUrls = siteUrls;
export const TokenResult = tokenResult;
export const TransmitConnection = transmitConnection;
export const UserNamePageErrorCodes = userNamePageErrorCodes;
