export const Logging = {
  AlertDebugLogs: false,
  EnabledLevels: ['DEBUG', 'INFO', 'ERROR'],
  Level: {
    Debug: 'DEBUG',
    Info: 'INFO',
    Error: 'ERROR',
  },
};

export const ReferralSite = {
  Blend: 'blend',
};

export const ReferralProduct = {
  Heloc: 'heloc',
};

export const SessionTimeOut = 600000; // 10 Minutes

export const SiteUrls = {
  About: 'https://www.regionstest.com/about-regions',
  AccessibleBanking:
    'https://www.regionstest.com/about-regions/accessible-banking',
  BlendNewCustomer:
    'https://regionsqa.beta.blendlabs.com/public/redirect/signup?loanType=HELOC',
  BlendSSO:
    'https://regionsqa.beta.blendlabs.com/auth/saml/borrower?returnIdp=true',
  EconomicReports: 'https://www.regionstest.com/about-regions/economic-update',
  EnrollNow: 'https://onlinebanking.regionstest.com/enrollment/home',
  EnterpriseAuth:
    'https://login.regionstest.com/signin?channel=blnd&product=heloc',
  ForgotPassword:
    'https://onlinebanking.regionstest.com/customerservice/forgottenpassword',
  ForgotUserName:
    'https://onlinebanking.regionstest.com/customerservice/Recoveruserid',
  PrivacyAndSecurity:
    'https://www.regionstest.com/about-regions/privacy-security',
  WebsiteTermsOfUse:
    'https://www.regionstest.com/about-regions/privacy-security/terms-conditions',
  InvestorRelations: 'https://ir.regionstest.com',
  OnlineTrackingAdvertising:
    'https://www.regionstest.com/about-regions/privacy-security/online-privacy-notice',
  LeaveFeedback:
    'https://survey.regions.com/jfe/form/SV_0Jm6o1kcV7mAmA5?source=loheloc',
};

export const TransmitConnection = {
  AppId: 'blend-web',
  Endpoint: 'https://rdoauth.regionstest.com',
  PolicyId: 'login',
};

export const TokenResult = {
  ErrorResultActions: [
    'insufficientquestions',
    'inactiveinia',
    'lockedoutinia',
    'questionsnotsetupinia',
    'username profile issue',
  ],
  ResultAction: {
    InsufficientQuestions: 'insufficientquestions',
    InactiveInIA: 'inactiveinia',
    LockedOutInIA: 'lockedoutinia',
    QuestionsNotSetUpInIa: 'questionsnotsetupinia',
    UsernameProfileIssue: 'username profile issue',
  },
  ErrorCode: {
    PasswordLockout: '007a401',
    InactiveUser: '001ts401',
    FIDisabled: '006a401',
    TempPasswordExpired: '025a401',
  },
  ErrorCodes: ['007a401', '001ts401', '006a401', '025a401'],
};

export const LockedOutErrorCodes = {
  PasswordLockedout: '1',
  ChallengeQuestionLocked: '2',
  InactiveUser: '3',
  Disabled: '4',
  TempPasswordExpired: '5',
  TempCredentialsLockedOut: '6',
  InsufficientQuestions: '7',
  QuestionsNotSetUp: '8',
  InvalidAccount: '9',
};

export const UserNamePageErrorCodes = {
  SessionExpired: '1',
  InvalidAccount: '2',
};
