/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const IconChevron = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 8 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Chevron icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-569.000000, -51.000000)"
        fill="#007C8C"
        fillRule="nonzero"
      >
        <path
          d="M578.094812,54.2411537 C578.513911,53.8220545 579.193406,53.8220545 579.612505,54.2411537 C580.001668,54.6303173 580.029466,55.244005 579.695897,55.6652435 L579.612505,55.7588463 L573.758846,61.6125048 C573.369683,62.0016684 572.755995,62.0294658 572.334757,61.695897 L572.241154,61.6125048 L566.387495,55.7588463 C565.968396,55.3397471 565.968396,54.6602529 566.387495,54.2411537 C566.776659,53.8519901 567.390346,53.8241927 567.811585,54.1577615 L567.905188,54.2411537 L573,59.3365854 L578.094812,54.2411537 Z"
          id="Chevron"
          transform="translate(573.000000, 57.926829) rotate(-90.000000) translate(-573.000000, -57.926829) "
        />
      </g>
    </g>
  </svg>
);

IconChevron.propTypes = {
  className: PropTypes.string,
};

IconChevron.defaultProps = {
  className: '',
};

export default IconChevron;
