/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable indent */
import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import BlendSegmentation from './views/BlendSegmentation';
import PageNotFound from './components/PageNotFound';
import BusinessUserMessaging from './components/BusinessUserMessaging';

function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<BlendSegmentation />} />
        <Route path="/businessuser" element={<BusinessUserMessaging />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/segmentation" element={<BlendSegmentation />} />
      </Routes>
    </Fragment>
  );
}

export default App;
