/* eslint-disable react/no-danger */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const FooterDisclosures = ({ disclosures }) => (
  <div className="footer__disclosures">
    {disclosures.map((disclosure) => (
      <p key={disclosure} className="footer__disclosure">
        <em dangerouslySetInnerHTML={{ __html: disclosure }} />
      </p>
    ))}
  </div>
);

FooterDisclosures.propTypes = {
  disclosures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FooterDisclosures;
